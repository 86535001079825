import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  AssignKycDirector,
  CompanyProfileKyc,
  CorporateKyc,
  IndividualKyc,
  NextOfKinKyc,
  ProfileKyc,
} from '@core/models/client/kyc';
import { Observable, catchError, finalize, firstValueFrom, map } from 'rxjs';
import { LoadingService } from '../loading.service';

@Injectable({
  providedIn: 'root',
})
export class KycService {
  http = inject(HttpClient);
  private loadingService = inject(LoadingService);
  BASE_URL = environment.CLIENT_BASE_URL + '/v1';
  handler = inject(HttpBackend);

  constructor() {}

  assignKycDirectors(kycDirectorsInfo: AssignKycDirector) {
    return this.http.post(this.BASE_URL, kycDirectorsInfo);
  }

  addCorporateKyc(corporateKycInfo: CorporateKyc) {
    return this.http.post(this.BASE_URL, corporateKycInfo);
  }

  addIndividualKyc(individualKycInfo: IndividualKyc) {
    return this.http.post(this.BASE_URL, individualKycInfo);
  }

  updateProfile(formData: ProfileKyc):Observable<any> {
    return this.http
      .post<any>(
        `${this.BASE_URL}/users/update-profile`,
        formData
      )
  }
  addCompanyProfile(formData: CompanyProfileKyc):Observable<any> {
    return this.http
      .post<any>(
        `${this.BASE_URL}/kyc/add-corporate-kyc`,
        formData
      )
  }
  addNextOfKin(formData: NextOfKinKyc):Observable<any> {
    return this.http
      .post<any>(
        `${this.BASE_URL}/kyc/add-next-of-kin-kyc`,
        formData
      )
  }

  updateNextOfKin(formData: NextOfKinKyc):Observable<any> {
    return this.http
      .patch<any>(
        `${this.BASE_URL}/kyc/add-next-of-kin-kyc`,
        formData
      )
  }

  async getPresignedUrl(account_id: string): Promise<string> {
    try {
      const response = await firstValueFrom(
        this.http
          .get<{
            status: string;
            message: string;
            data: { presigned_url: string };
          }>(`${this.BASE_URL}?account_id=${account_id}`)
          .pipe(
            map((response) => {
              if (!response || !response.data || !response.data.presigned_url) {
                throw new Error('Invalid response from server');
              }
              return response.data.presigned_url;
            }),
            catchError((error) => {
              console.error('Error fetching presigned URL', error);
              throw error;
            })
          )
      );
      if (!response) {
        throw new Error('Presigned URL is undefined');
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  uploadKycDocument(formData: any) {
    const uniqueStateUrl = 'add-kyc-document';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.BASE_URL}/kyc/add-kyc-document`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addMeansOfId(formData: any): Observable<any> {
    const uniqueStateUrl = 'add-means-of-identity-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.BASE_URL}/kyc/add-means-of-identity-kyc`, formData)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addBankDetails(formData: any): Observable<CreateBankDetailsResponse> {
    const uniqueStateUrl = 'add-banking-details-to-account';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post<CreateBankDetailsResponse>(
        `${this.BASE_URL}/account/add-banking-details-to-account`,
        formData
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addEmployerDetails(formData: any): Observable<CreateBankDetailsResponse> {
    const uniqueStateUrl = 'add-employer-details';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post<CreateBankDetailsResponse>(
        `${this.BASE_URL}/kyc/add-employer-kyc`,
        formData
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateEmployerDetails(formData: any): Observable<CreateBankDetailsResponse> {
    const uniqueStateUrl = 'update-employer-details';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post<CreateBankDetailsResponse>(
        `${this.BASE_URL}/kyc/update-employer-kyc`,
        formData
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  inviteSignatory(
    inviteSignatoryPayload: any
  ): Observable<InviteSignatoryData> {
    const uniqueStateUrl = 'invite-signatory';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<InviteSignatory>(
        `${this.BASE_URL}/account/invite-account-signatory`,
        inviteSignatoryPayload
      )
      .pipe(
        map((response: InviteSignatory) => response.date),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  uploadFile(url: string, formData: any): Promise<any> {
    this.http = new HttpClient(this.handler);

    return firstValueFrom(
      this.http
        .put(url, formData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/pdf',
          }),
        })
        .pipe(
          catchError((error) => {
            throw error;
          })
        )
    );
  }
  directorDetails(formData: any): Observable<any> {
    const uniqueStateUrl = 'add-corporate-director-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.BASE_URL}/kyc/add-corporate-director-kyc`, formData)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getKycDirector(formData: any): Observable<any> {
    const uniqueStateUrl = 'get-corporate-director-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.BASE_URL}/kyc/get-kyc-directors`, formData)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getAccountSignatories(accountId: string): Observable<GetAccountSignatories> {
    const uniqueStateUrl = 'get-account-signatories';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<GetAccountSignatories>(
        `${this.BASE_URL}/account/get-account-attached-profiles`,
        {
          account_id: accountId,
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}



export interface CreateBankDetailsResponse {
  status: string;
  message: string;
  data: CreateBankDetailsDatum[];
}

interface CreateBankDetailsDatum {
  bank_account_name: string;
  bank_account_number: string;
  bank_code: string;
  bank_sort_code: string;
  bank_branch: string;
  id: string;
  updated_at: string;
  created_at: string;
}

export interface GetKycDirectorData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  bvn: string;
  update_at: string;
  created_at: string;
  company_id: string;
}

export interface InviteSignatory {
  status: string;
  message: string;
  date: InviteSignatoryData;
}
export interface InviteSignatoryData {
  status: string;
  user: User;
  signatory_class: string;
  signatory_id: string;
}

export interface User {
  first_name: string;
  last_name: string;
  email: string;
  phone_number_1: null;
  phone_number_2: null;
  id: string;
}

export interface GetAccountSignatories {
  status: string;
  message: string;
  data: GetAccountSignatoriesData[];
}


export interface GetAccountSignatoriesData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number_1?: null | string;
  phone_number_2?: null | string;
  id: string;
}

